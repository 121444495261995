import { create } from 'zustand'
import { DeliveryHelper, DeliveryStore } from './types'
import DeliveryMode from 'types/deliveryMode'

export const useDeliveryForm = create<DeliveryStore>(set => ({
    phone: '',
    address: [],
    deliveryMode: DeliveryMode.INMEDIATE.toString(),
    observation: '',
    paymentMethod: '',
    priceDelivery: '',
    date: '',
    hour: '',
    cashClient: '',
    set: data => set(data),
    clear: () =>
        set(
            state => ({
                clear: state.clear,
                set: state.set,
                address: [],
                phone: '',
                driver: null,
                observation: '',
                paymentMethod: '',
                priceDelivery: '',
                deliveryMode: DeliveryMode.INMEDIATE.toString(),
                date: '',
                hour: '',
                cashClient: ''
            }),
            true
        )
}))

export const useDeliveryHelper = create<DeliveryHelper>(set => ({
    phone: '',
    address: '',
    paymentMethod: '',
    priceDelivery: '',
    deliveryMode: '',
    date: '',
    hour: '',
    cashClient: '',
    setHelpers: obj => set(obj),
    clear: () =>
        set(
            state => ({ clear: state.clear, setHelpers: state.setHelpers }),
            true
        )
}))
