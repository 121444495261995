import {
    Badge,
    Box,
    Center,
    Flex,
    Heading,
    Spinner,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import Initial from '../../components/Initial'
import NavbarAlt from '../../components/NavbarAlt'
import useDelivery from '../../hooks/useDelivery'
import Summary from './components/Summary'
import ClientsList from './components/ClientsList'
import ClientForm from './components/ClientForm'
import { OverlapLoaderProvider } from '../../context/OverlapLoaderContext'
import CloseOrGoToPay from './components/CloseOrGoToPay'
import useInitial from './hooks/useInitial'
import { useDeliveryForm, useDeliveryHelper } from './store/delivery-form.store'
import { getColorStatus, getStatusNames } from '../Deliveries/utils'
import { DeliveryStatus } from '../../types/deliveryMode'
import ClientUpdate from './components/ClientUpdate'

export default function DeliveryCustomers() {
    const isMobile =
        useBreakpointValue<boolean>({ base: true, md: false }) || false
    const {
        client,
        setClient,
        setDelivery,
        setProducts,
        orderEdition,
        delivery
    } = useDelivery()
    const { isLoading } = useInitial()
    const clear = useDeliveryForm(state => state.clear)
    const clearHelpers = useDeliveryHelper(state => state.clear)

    function backHandler() {
        setClient(null)
        setDelivery(null)
        clear()
        setProducts([])
        clearHelpers()
        orderEdition(false)
    }

    return (
        <Initial minH="100vh" bg="base-2">
            <NavbarAlt
                title="Nuevo delivery"
                path="/deliveries"
                onClick={backHandler}
            />
            {isLoading && (
                <Center minH="calc(100vh - 60px)">
                    <Spinner />
                </Center>
            )}
            {!isLoading && (
                <OverlapLoaderProvider>
                    <Flex position="relative">
                        {((isMobile && !client) || !isMobile) && (
                            <ClientsList
                                client={client}
                                onClient={setClient}
                                isMobile={isMobile}
                            />
                        )}
                        {!client && (
                            <Text
                                p={6}
                                color="gray.500"
                                display={{ base: 'none', md: 'block' }}
                            >
                                Seleccione un cliente
                            </Text>
                        )}
                        {client && (
                            <Box
                                w="full"
                                px={[3, 3, 4, 6]}
                                pt={[3, 3, 4, 6]}
                                pb={{ base: 20, md: 6 }}
                            >
                                <Flex
                                    mb={4}
                                    justify="space-between"
                                    alignItems="center"
                                    gap={2}
                                >
                                    <Box>
                                        <Box
                                            display="flex"
                                            gap={2}
                                            alignItems={'center'}
                                        >
                                            <Heading
                                                size="md"
                                                color="gray.700"
                                                fontWeight="medium"
                                            >
                                                {client.firstname
                                                    ? `${client.firstname} ${client.lastname}`
                                                    : client.business_name}
                                            </Heading>
                                            {client.firstname ? (
                                                <>
                                                    <ClientUpdate />
                                                </>
                                            ) : null}
                                        </Box>

                                        {delivery && (
                                            <Box display={'flex'} gap={2}>
                                                <Heading
                                                    size="sm"
                                                    color="gray.700"
                                                    fontWeight="medium"
                                                >
                                                    {delivery
                                                        ? `(#${delivery.id})`
                                                        : ''}
                                                </Heading>

                                                <Badge
                                                    colorScheme={getColorStatus(
                                                        delivery.status
                                                    )}
                                                    fontWeight="normal"
                                                    variant="solid"
                                                    rounded="md"
                                                    textTransform="lowercase"
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                >
                                                    {getStatusNames(
                                                        delivery.status
                                                    )}
                                                </Badge>
                                            </Box>
                                        )}
                                    </Box>
                                    {delivery?.status !==
                                        DeliveryStatus.CANCELED && (
                                        <CloseOrGoToPay />
                                    )}
                                </Flex>

                                <Flex
                                    gap={3}
                                    flexDir={{ base: 'column', md: 'row' }}
                                >
                                    <ClientForm />
                                    <Summary isMobile={isMobile} />
                                </Flex>
                            </Box>
                        )}
                    </Flex>
                </OverlapLoaderProvider>
            )}
        </Initial>
    )
}
