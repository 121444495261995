import { CommandItemModifier } from 'models/command_item'
import { DataModifierGroup, ModifierGroup } from './types'

export const getCommandModifierGroups = (
    commandItemModifiers: CommandItemModifier[]
): ModifierGroup[] => {
    let data: {
        [key: string]: DataModifierGroup[]
    } = {}

    for (const modifierOrder of commandItemModifiers) {
        const modifierGroupModifier = modifierOrder.modifier_group
        const modifier_group_id = modifierGroupModifier?.id
        if (!modifier_group_id) continue

        const dataModifierGroup = {
            name_modifier_group: modifierGroupModifier?.name ?? '',
            id: modifierOrder.id,
            modifier_id: modifierOrder.modifier.id,
            name: modifierOrder.modifier?.name ?? '',
            quantity: modifierOrder.quantity
        }

        if (data[modifier_group_id]) {
            const arrayData = data[modifier_group_id]

            const newData = [...arrayData, { ...dataModifierGroup }]
            data = {
                ...data,
                [modifier_group_id]: newData
            }
        } else {
            data = {
                ...data,
                [modifier_group_id]: [
                    {
                        ...dataModifierGroup
                    }
                ]
            }
        }
    }

    const modifierGroups = []

    for (const [key, value] of Object.entries(data)) {
        const _value = value
        const firstValue = _value?.[0]
        const dataModifierGroup = {
            modifier_group_id: Number(key),
            name: firstValue?.name_modifier_group,
            modifier_group_modifiers: _value.map(_data => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { name_modifier_group, ...rest } = _data
                return rest
            })
        }
        modifierGroups.push(dataModifierGroup)
    }

    return modifierGroups
}
