import { StoragePage } from './types'

export const storagePage: StoragePage = {
    to: 'eat',
    type: null,
    condition: 'cash',
    discount: 0,
    typeDiscount: 'amount',
    clientSelected: null,
    cash: [],
    card: [],
    transfer: [],
    alias: '',
    print_sale_note: '0',
    generate_electronic_receipts: '0',
    table_id: 0,
    selectedItems: [],
    typeTax: 'levied',
    paymentTypes: [],
    deliveryId: 0,
    deliveryPrice: 0,
    creditFees: [],
    reason: '',
    focusAlias: false
}
