import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'

export default function useCommandHistory(
    commandId: number,
    deliveryId?: number
) {
    const toast = useToast()
    const [loading, setLoading] = useState(false)

    async function printCommand() {
        setLoading(true)

        const endpoint = deliveryId
            ? `/api/deliveries/${deliveryId}/commands/print`
            : `/api/commands/print`

        const { error } = await post<any>(endpoint, {
            command_id: commandId
        })
        setLoading(false)
        if (error) {
            toast({
                status: 'error',
                title: error.message,
                position: 'top'
            })
        } else {
            toast({
                status: 'success',
                title: 'Comanda obtenida',
                position: 'top'
            })
        }
    }

    return { printCommand, loading, setLoading }
}
