import React from 'react'
import { ModifierGroup } from 'types/runway_state'

interface Props {
    modifierGroup: ModifierGroup
}

export default function CommandTextModifierGroupModifiers({
    modifierGroup
}: Props) {
    return (
        <>
            {modifierGroup.name} :{' '}
            {modifierGroup.modifier_group_modifiers
                .map(fssf => `${fssf.name}${` x${fssf.quantity}`}`)
                .join(', ')}
        </>
    )
}
