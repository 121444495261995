import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Spinner,
    Text
} from '@chakra-ui/react'
import HeadDetails from './HeadDetails'
import ButtonsOptions from './ButtonGroups/ButtonsOptions'
import Menu from './Menu'
import ButtonSaveOrder from './ButtonGroups/ButtonSaveOrder'
import useOrderByTable from '../../hooks/useOrderByTable'
import colors from '../../../../config/theme/colors'
import { useState } from 'react'
import useParamsQuickSaleBy from '../../hooks/useParamsQuickSaleBy'
import ButtonSavePreOrder from './ButtonGroups/ButtonSavePreOrder'
import usePreOrder from '../../hooks/usePreOrder'
import useMenu from '../../../../hooks/useMenu'
import useCashbox from '../../hooks/useCashbox'

export default function Details() {
    const { by } = useParamsQuickSaleBy()
    const { getPriceTotal, menu } = useMenu()
    const { isModeEdition, isSuccess } = useOrderByTable()
    const { isLoading: isLoadingPreOrder } = usePreOrder()
    /**
     * Solo para mode movil
     */
    const [isOpen, setIsOpen] = useState(false)

    const isLoading = by === 'tables' ? !isSuccess : isLoadingPreOrder

    const { status } = useCashbox()
    const isOpenCashbox = status !== 'closed'

    return (
        <>
            <Flex
                minW={{
                    xl: '450px',
                    lg: '355px'
                }}
                py="6"
                position={{ base: 'fixed', lg: 'relative' }}
                left={0}
                right={0}
                top={0}
                bottom={0}
                display={{ base: isOpen ? 'flex' : 'none', lg: 'flex' }}
                flexDirection="column"
                justifyContent="space-between"
                minHeight={{
                    base: 'auto',
                    lg: `calc(100vh - ${!isOpenCashbox ? '86' : '60'}px)`
                }}
                bg="base-3"
                overflow="hidden"
                zIndex={11}
            >
                {isLoading && (
                    <Center p={12}>
                        <Spinner />
                    </Center>
                )}
                {!isLoading && (
                    <Box>
                        <HeadDetails onBack={() => setIsOpen(false)} />
                        <Divider mb="2" />
                        <Box overflow="auto" maxHeight={'calc(100vh - 300px)'}>
                            <Menu />
                        </Box>
                    </Box>
                )}
                {!isLoading && (
                    <Box w="full" px={{ base: 3, lg: 6 }}>
                        <Flex
                            mb="4"
                            justifyContent="space-between"
                            fontWeight="bold"
                        >
                            <Text>TOTAL</Text>
                            <Text fontWeight="bold">
                                S/{getPriceTotal().toFixed(2)}
                            </Text>
                        </Flex>
                        {!isModeEdition && by === 'tables' && (
                            <ButtonsOptions />
                        )}
                        {isModeEdition && by === 'tables' && (
                            <ButtonSaveOrder />
                        )}
                        {by === 'pre_orders' && <ButtonSavePreOrder />}
                    </Box>
                )}
            </Flex>
            {!isLoading && (
                <Box
                    display={{ base: 'flex', lg: 'none' }}
                    p={5}
                    position="absolute"
                    bottom={0}
                    w="full"
                >
                    <Button
                        w="100%"
                        bg={colors.dark}
                        color={colors.white}
                        _hover={{
                            background: colors['gray-3']
                        }}
                        borderRadius={20}
                        onClick={() => setIsOpen(true)}
                    >
                        Ver pedido (
                        {menu.reduce((n, item) => item.quantity + n, 0)})
                    </Button>
                </Box>
            )}
        </>
    )
}
