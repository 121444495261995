import {
    Button,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Input,
    FormLabel,
    VStack,
    FormControl,
    FormErrorMessage
} from '@chakra-ui/react'
import Pencil from 'components/Icon/Pencil'
import useDelivery from 'hooks/useDelivery'
import val from 'lib/val'
import useUpdateClient from '../../hooks/useUpdateClient'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'

interface ClientFormData {
    lastname: string
    firstname: string
}

export default function ClientUpdate() {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { client } = useDelivery()
    const { submit, isLoading } = useUpdateClient()

    const [helpers, setHelpers] = useState<Partial<ClientFormData>>({})
    const [form, setForm] = useState<ClientFormData>({
        lastname: '',
        firstname: ''
    })

    useEffect(() => {
        if (isOpen) {
            setForm({
                lastname: client?.lastname ?? '',
                firstname: client?.firstname ?? ''
            })
        } else {
            setHelpers({})
        }
    }, [isOpen])

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const name = ev.target.name as keyof ClientFormData
        const value = ev.target.value
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }

        setForm({
            ...form,
            [name]: value
        })
    }

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()

        const v = val<ClientFormData>(form)
        v.firstname.isEmpty('El nombre es requerido')
        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        await submit({
            firstname: form.firstname,
            lastname: form.lastname
        })

        onClose()
    }

    return (
        <>
            <Icon
                fill="blue.400"
                as={Pencil}
                cursor="pointer"
                onClick={onOpen}
            />

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    if (isLoading) return
                    onClose()
                }}
                size="lg"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Actualizar cliente</ModalHeader>
                    <form onSubmit={handleSubmit} noValidate>
                        <ModalBody pb={6}>
                            <VStack spacing={4}>
                                <FormControl
                                    isRequired
                                    isInvalid={!!helpers.firstname}
                                >
                                    <FormLabel>Nombres</FormLabel>
                                    <Input
                                        autoFocus
                                        name="firstname"
                                        placeholder="Ingresar nombres"
                                        value={form.firstname}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                        {helpers.firstname}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!helpers.lastname}>
                                    <FormLabel>Apellidos</FormLabel>
                                    <Input
                                        name="lastname"
                                        placeholder="Ingresar apellidos"
                                        value={form.lastname}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                        {helpers.lastname}
                                    </FormErrorMessage>
                                </FormControl>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="outline"
                                bg="transparent"
                                onClick={onClose}
                                disabled={isLoading}
                            >
                                Cancelar
                            </Button>
                            <Button
                                colorScheme="green"
                                ml={2}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Actualizar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
