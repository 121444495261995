import { Badge, Flex } from '@chakra-ui/layout'
import {
    FilterTabsProps,
    ItemTabProps,
    DeliveryStatuNamesSearch
} from './types'

export default function FilterTabs({
    tabValue,
    onTab,
    counts
}: FilterTabsProps) {
    const tabs: Array<[DeliveryStatuNamesSearch, string, number]> = [
        [
            'active',
            'Activos',
            counts.delivered +
                counts.inPreparation +
                counts.onTheWay +
                counts.pending
        ],
        ['pending', 'Pendientes', counts.pending],
        ['in_preparation', 'En preparación', counts.inPreparation],
        ['on_the_way', 'En ruta', counts.onTheWay],
        ['delivered', 'Entregados', counts.delivered],
        ['canceled', 'Anulados', counts.canceled],
        ['unpaid', 'Pendientes de pago', counts.pending_payments]
    ]

    const ItemTab = ({ isActive, label, onClick, quantity }: ItemTabProps) => {
        if (!isActive) {
            return (
                <Flex
                    cursor="pointer"
                    _hover={{
                        textDecor: 'underline'
                    }}
                    alignItems="center"
                    gap={1}
                    onClick={onClick}
                    whiteSpace="nowrap"
                >
                    <Badge fontSize="xs" rounded="full" fontWeight="medium">
                        {quantity}
                    </Badge>{' '}
                    {label}
                </Flex>
            )
        }

        return (
            <Flex
                color="red.400"
                fontWeight="medium"
                gap={1}
                alignItems="center"
                whiteSpace="nowrap"
            >
                <Badge
                    colorScheme="red"
                    rounded="full"
                    variant="solid"
                    fontSize="xs"
                    fontWeight="medium"
                >
                    {quantity}
                </Badge>
                {label}
            </Flex>
        )
    }

    return (
        <Flex
            gap={3}
            color="gray.500"
            fontSize="sm"
            px={{
                base: 6
            }}
            pt={{
                base: 3
            }}
            w={{
                base: 'full',
                md: 'auto'
            }}
            overflowX={{
                base: 'auto',
                md: 'initial'
            }}
        >
            {tabs.map(([value, label, quantity]) => (
                <ItemTab
                    key={value}
                    label={label}
                    onClick={() => onTab(value)}
                    isActive={tabValue === value}
                    quantity={quantity}
                />
            ))}
        </Flex>
    )
}
