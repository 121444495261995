import { IconButton } from '@chakra-ui/button'
import { Badge, Text } from '@chakra-ui/layout'
import { Td, Tr } from '@chakra-ui/table'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowRight from '../../../../components/Icon/ArrowRight'
import { DeliveryHistoryStatus } from '../../../../types/deliveryMode'
import StatusContent from './StatusContent'
import { DeliveryRowProps } from './types'
import Motorized from './Motorized'

export default function DeliveryRow({
    delivery,
    onLoad,
    profiles
}: DeliveryRowProps) {
    const [timerTexts, setTimerText] = useState({
        delivery: 'calculando',
        status: 'calculando...'
    })

    const { push } = useHistory()

    const total = useMemo(
        () =>
            delivery.order.order_items.reduce(
                (n, orderItem) => n + orderItem.price * orderItem.quantity,
                0
            ) + delivery.delivery_price,
        [delivery.updated_at]
    )

    useEffect(() => {
        const histories = delivery.delivery_histories
            .filter(dh => dh.status !== DeliveryHistoryStatus.PAID)
            .sort((a, b) => b.id - a.id)
        const history = histories[0]

        const timer = setInterval(() => {
            const today = dayjs().toISOString()
            const deliveryDate = dayjs(delivery.delivery_date)
            const diffDelivery = deliveryDate.diff(today, 'seconds')
            const timeDisplaysDelivery = dayjs
                .duration(diffDelivery, 'seconds')
                .humanize(true)

            if (!history) {
                setTimerText({
                    delivery: timeDisplaysDelivery,
                    status: 'error'
                })
                return
            }
            const dateStatus = dayjs(history.created_at)
            const diffStatus = dateStatus.diff(today, 'seconds')
            const minuteDisplays = dayjs
                .duration(diffStatus, 'seconds')
                .humanize(true)
            setTimerText({
                delivery: timeDisplaysDelivery,
                status: minuteDisplays
            })
        }, 1000)

        return () => clearInterval(timer)
    }, [delivery.updated_at])

    return (
        <Tr
            cursor="pointer"
            onClick={() => push(`/deliveries/${delivery.id}/customers`)}
        >
            <Td>
                <Text fontWeight="bold">#{delivery.id}</Text>
            </Td>
            <Td>
                <Text fontSize="sm" fontWeight={'bold'} color="gray.900">
                    {delivery.phone_number}
                </Text>
                <Text fontWeight="bold" color="blue.400">
                    {delivery.client.firstname} {delivery.client.lastname}
                </Text>
                <Text fontSize="xs" color="gray.500">
                    {delivery.address}
                </Text>
            </Td>
            <Td>
                <Text fontSize="xs" color="gray.900">
                    {dayjs(delivery.delivery_date).format('DD [de] MMMM')}
                </Text>
                <Text fontWeight="bold" fontSize="lg">
                    {dayjs(delivery.delivery_date).format('hh:mm a')}
                </Text>
                <Text fontSize="xs" fontWeight="light" color="gray.500">
                    {timerTexts.delivery}
                </Text>
            </Td>
            <Td>
                <Motorized delivery={delivery} onLoad={onLoad} />
            </Td>
            <Td>
                <StatusContent
                    onLoad={onLoad}
                    delivery={delivery}
                    timer={timerTexts.status}
                    profiles={profiles}
                />
            </Td>
            <Td>
                <Text mb={1} fontWeight="bold">
                    {total.toFixed(2)} PEN
                </Text>
                <Badge
                    colorScheme={delivery.is_paid ? 'green' : 'orange'}
                    textTransform="lowercase"
                    variant="subtle"
                    fontWeight="medium"
                    rounded="md"
                >
                    {delivery.is_paid ? 'Pagado' : 'Pago pendiente'}
                </Badge>
            </Td>
            <Td p={0}>
                <IconButton
                    aria-label="go"
                    icon={<ArrowRight />}
                    variant="link"
                />
            </Td>
        </Tr>
    )
}
