import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { CustomCommandItem } from './types'
import { getCommandModifierGroups } from './utils'
import CommandTextModifierGroupModifiers from './CommandTextModifierGroup'

interface Props {
    commandItem: CustomCommandItem
}

export default function CommandItem({ commandItem }: Props) {
    const commandModifierGroups = getCommandModifierGroups(
        commandItem?.command_item_modifiers ?? []
    )

    return (
        <Flex gridGap="2" mb={1.5}>
            <Text minW="12" align="center">
                {commandItem.quantity}
            </Text>
            <Box>
                <Text>{commandItem.item.name}</Text>
                {commandModifierGroups.map((cMG, i) => (
                    <Text key={i} fontSize="sm">
                        <CommandTextModifierGroupModifiers
                            modifierGroup={cMG}
                        />
                    </Text>
                ))}
                {commandItem.aditional_information && (
                    <Text fontSize="sm" lineHeight={1}>
                        {commandItem.aditional_information}
                    </Text>
                )}
            </Box>
        </Flex>
    )
}
