import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import CategoryItem from '../../../models/category_item'
import { formCategory, listCategory } from '../storage'
import FormCategory from './FormCategory'

export default function useCategory(category?: CategoryItem) {
    const { validation, observer, submit } = useSubmit(formCategory, {
        path: category
            ? `/api/category_items/${category.id}`
            : '/api/category_items',
        method: category ? 'put' : 'post',
        done: () => listCategory.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.printer_id.isEmpty('Es requerido la impresora')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        printer_id: parseInt(f.printer_id)
    }))

    function openModal() {
        if (category) {
            formCategory.store({
                name: category.name,
                printer_id: category.printer_id.toString() || ''
            })
            formCategory.init(formCategory.store)
        } else {
            formCategory.init()
        }
        console.log('aca')
        modal(FormCategory, {
            title: category ? 'Editar Categoría' : 'Nueva Categoría',
            onSubmit: submit
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    printer_id: number
}
