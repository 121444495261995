import {
    FormControl,
    FormErrorMessage,
    FormLabel
} from '@chakra-ui/form-control'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import { RadioGroup } from '@chakra-ui/react'
import useDelivery from '../../../../hooks/useDelivery'
import { DeliveryStatus, PaymentMethod } from '../../../../types/deliveryMode'
import onlyNumber from '../../../../utilities/onlyNumber'
import BoxContent from '../../shared/BoxContent'
import RadioLabel from '../../shared/RadioLabel'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../../store/delivery-form.store'
import { paymentLogos } from '../../utils/paymentLogos'

export default function PaymentDetails() {
    const priceDelivery = useDeliveryForm(state => state.priceDelivery)
    const cashClient = useDeliveryForm(state => state.cashClient)
    const paymentMethod = useDeliveryForm(state => state.paymentMethod)
    const set = useDeliveryForm(state => state.set)
    const helpers = useDeliveryHelper()
    const { delivery } = useDelivery()

    return (
        <BoxContent title="Detalles de pago">
            <FormControl my={2} isInvalid={!!helpers.paymentMethod}>
                <FormLabel
                    color="gray.700"
                    fontWeight="normal"
                    fontSize="sm"
                    mb={0}
                >
                    Método de pago
                </FormLabel>
                <RadioGroup
                    name="payment"
                    value={paymentMethod}
                    onChange={val => {
                        set({ paymentMethod: val })
                        helpers.setHelpers({ paymentMethod: '' })
                    }}
                    isDisabled={
                        !!delivery?.is_paid ||
                        delivery?.status === DeliveryStatus.CLOSED ||
                        delivery?.status === DeliveryStatus.CANCELED
                    }
                >
                    {paymentLogos.map(paymentLogo => (
                        <RadioLabel
                            key={paymentLogo.value}
                            value={paymentLogo.value}
                            label={paymentLogo.label}
                            image={paymentLogo.src}
                        />
                    ))}
                </RadioGroup>
                <FormErrorMessage>{helpers.paymentMethod}</FormErrorMessage>
            </FormControl>
            {paymentMethod === PaymentMethod.CASH.toString() && (
                <FormControl mb={2} isInvalid={!!helpers.cashClient}>
                    <FormLabel
                        color="gray.700"
                        fontWeight="normal"
                        fontSize="sm"
                        mb={0}
                    >
                        Pago por cliente
                    </FormLabel>
                    <InputGroup size="sm">
                        <InputLeftElement
                            color="blackAlpha.600"
                            pointerEvents="none"
                        >
                            S/
                        </InputLeftElement>
                        <Input
                            bg="white"
                            rounded="md"
                            // type="number"
                            isReadOnly={!!delivery?.is_paid}
                            isDisabled={
                                delivery?.status === DeliveryStatus.CLOSED ||
                                delivery?.status === DeliveryStatus.CANCELED
                            }
                            placeholder="Ingrese el monto"
                            value={cashClient}
                            onChange={ev => {
                                set({
                                    cashClient: onlyNumber(ev.target.value, {
                                        max: 99999
                                    })
                                })
                                helpers.setHelpers({ cashClient: '' })
                            }}
                        />
                    </InputGroup>
                    <FormErrorMessage>{helpers.cashClient}</FormErrorMessage>
                </FormControl>
            )}
            <FormControl mb={2} isInvalid={!!helpers.priceDelivery}>
                <FormLabel
                    color="gray.700"
                    fontWeight="normal"
                    fontSize="sm"
                    mb={0}
                >
                    Precio del delivery
                </FormLabel>
                <InputGroup size="sm">
                    <InputLeftElement
                        color="blackAlpha.600"
                        pointerEvents="none"
                    >
                        S/
                    </InputLeftElement>
                    <Input
                        bg="white"
                        rounded="md"
                        // type="number"
                        //isReadOnly={!!delivery?.is_paid}
                        isDisabled={
                            !!delivery?.is_paid ||
                            delivery?.status === DeliveryStatus.CLOSED ||
                            delivery?.status === DeliveryStatus.CANCELED
                        }
                        placeholder="Ingrese costo"
                        value={priceDelivery}
                        onChange={ev => {
                            set({
                                priceDelivery: onlyNumber(ev.target.value, {
                                    max: 99999
                                })
                            })
                            helpers.setHelpers({ priceDelivery: '' })
                        }}
                    />
                </InputGroup>
                <FormErrorMessage>{helpers.priceDelivery}</FormErrorMessage>
            </FormControl>
        </BoxContent>
    )
}
