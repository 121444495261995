import React, { FormEvent, useEffect, useState } from 'react'
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast,
    VStack
} from '@chakra-ui/react'
import Profile from 'models/profile'
import Delivery from 'models/delivery'
import { get, put } from 'lib/http'
import useSession from 'hooks/useSession'

interface Props {
    isOpen: boolean
    handleClose: () => void
    delivery: Delivery
    onLoad: () => void
}

export default function UpdateMotorizedModal({
    isOpen,
    handleClose,
    delivery,
    onLoad
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [profileId, setProfileId] = useState<string>('')
    const [hasError, setHasError] = useState(false)
    const [dataProfiles, setDataProfiles] = useState<Profile[]>([])

    const { session } = useSession()

    const toast = useToast()

    const getProfiles = async () => {
        const { data, error } = await get<Profile[]>('/api/profiles')

        if (error) {
            handleClose()
            toast({
                title: 'No fue posible recuperar los motorizados',
                status: 'error',
                position: 'top'
            })
            return
        }

        if (data) {
            setDataProfiles(data.result)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getProfiles()
        }
    }, [isOpen])

    // Se filtra el motorizado ya seleccionado
    const profiles = dataProfiles
        .filter(profile => profile.id !== delivery.profile_id)
        .map(profile => {
            return {
                label: `${profile.firstname} ${profile.lastname}`,
                value: profile.id
            }
        })

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!profileId) {
            setHasError(true)
            return
        }

        setIsLoading(true)

        const { error } = await put(
            `/api/deliveries/${delivery.id}/update_motorized`,
            {
                profileId: Number(profileId),
                userId: session?.user.id as number
            }
        )

        setIsLoading(false)

        handleClose()

        if (error) {
            toast({
                title: 'No fue posible actualizar el motorizado',
                status: 'error',
                position: 'top'
            })
            return
        }

        toast({
            title: 'Se actualizo el motorizado',
            status: 'success',
            position: 'top'
        })
        onLoad()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                if (isLoading) return
                handleClose()
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit}>
                    <ModalHeader>Actualizar motorizado</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl isInvalid={hasError}>
                                <FormLabel>Motorizado</FormLabel>
                                <Select
                                    placeholder="Seleccionar Motorizado"
                                    autoFocus
                                    fontSize={'sm'}
                                    onClick={ev => ev.stopPropagation()}
                                    onChange={ev =>
                                        setProfileId(ev.target.value)
                                    }
                                    value={profileId}
                                    disabled={isLoading}
                                >
                                    {profiles.map(profile => (
                                        <option
                                            key={profile.value}
                                            value={profile.value}
                                        >
                                            {profile.label}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter gap={2}>
                        <Button onClick={handleClose} disabled={isLoading}>
                            Cancelar
                        </Button>
                        <Button
                            isLoading={isLoading}
                            type="submit"
                            colorScheme="green"
                        >
                            Actualizar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
