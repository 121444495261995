import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react'
import GroupItem from '../../Groups/GroupItem'
import usePrintAgencyConsumption from '../../hooks/usePrintAgencyConsumption'
import ContentForm from './ContentForm'

export default function ModalAgencyConsumption() {
    const { isLoading, submit } = usePrintAgencyConsumption()
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <GroupItem onClick={onOpen} label="Consumo de Agencias por día" />
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
                <ModalOverlay />
                <ModalContent p={4} pb={6}>
                    <ModalHeader textAlign="center">
                        Reporte de Consumo de Agencias por Día
                    </ModalHeader>
                    <ModalBody>
                        <ContentForm onSubmit={submit} isLoading={isLoading} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
