import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FormEvent, useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { get } from '../../../lib/http'
import extractDataForm from '../../../utilities/extractDataForm'

import { DataForm, ReportAgencyConsumption } from './types'

export default function usePrintAgencyConsumption() {
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const head: (...values: string[]) => Row[number][] = (...values) =>
        values.map<Row[number]>(val => ({
            value: val,
            fontWeight: 'bold',
            wrap: true,
            alignVertical: 'center',
            align: 'left',
            fontSize: 11
        }))

    function setDataAgencyConsumption(
        data: ReportAgencyConsumption[],
        rows: SheetData
    ) {
        data.forEach(item => {
            rows.push([
                {
                    value: item.date,
                    type: String
                },
                {
                    value: item.agency,
                    type: String
                },
                {
                    value: item.guide,
                    type: String
                },
                {
                    value: item.arrival_time,
                    type: String
                },
                {
                    value: item.passengers,
                    type: Number
                },
                {
                    value: item.total,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                }
            ])
        })
    }

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<DataForm>(ev.target)
        const startDate = dayjs
            .tz(form.startDate + ' 00:00:00', 'America/Lima')
            .toISOString()
        const endDate = dayjs
            .tz(form.endDate + ' 23:59:59', 'America/Lima')
            .toISOString()

        const rows: SheetData = [
            [],
            [
                {
                    value: 'Reporte de Consumo de Agencias por Día',
                    fontWeight: 'bold',
                    span: 6,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [
                {
                    value: `De: ${dayjs(startDate).format(
                        'DD/MM/YYYY'
                    )} - Hasta ${dayjs(endDate).format('DD/MM/YYYY')}`,
                    span: 6,
                    align: 'center'
                }
            ],
            [],
            head(
                ...[
                    'Fecha',
                    'Agencia',
                    'Guía',
                    'Hora de llegada',
                    'Pasajeros',
                    'Monto consumido (S/)'
                ]
            )
        ]

        setIsLoading(true)

        const { data, error } = await get<ReportAgencyConsumption[]>(
            `/api/reports/agency_consumption?start_date=${startDate}&end_date=${endDate}`
        )

        if (error) {
            toast({
                title: 'Hubo un error al cargar los datos',
                status: 'error',
                position: 'top'
            })
            return
        }

        setDataAgencyConsumption(data.result, rows)

        await writeXlsxFile(rows, {
            fileName: `Reporte_de_consumo_por_agencia_${dayjs(startDate).format(
                'DD/MM/YYYY'
            )}_${dayjs(endDate).format('DD/MM/YYYY')}.xlsx`,
            columns: [20, 30, 20, 20, 15, 15].map(width => ({
                width
            })),
            fontSize: 10,
            fontFamily: 'Calibri'
        })

        setIsLoading(false)
    }

    return {
        submit,
        isLoading
    }
}
