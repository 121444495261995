import React from 'react'
import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react'
import ModalKeyboard from '../../../../components/ModalKeyboard'
import usePaymentMethods from '../../hooks/usePaymentMethods'
import { PaymentTypeData } from '../../../ConfirmOrder/types'

const amounts_cash = [5, 10, 20, 50, 100, 200]

interface Props {
    disabledButton: boolean
    cashPaymentType: PaymentTypeData
    attributes: any
    totalPay: number
}

export default function CashPayments({
    disabledButton,
    cashPaymentType,
    attributes,
    ...props
}: Props) {
    const { totalPay } = props
    const { cashHandler } = usePaymentMethods()

    return (
        <Box gap={2}>
            <Stack
                border="1px solid"
                borderColor={'blackAlpha.500'}
                borderRadius={'md'}
                mb={2}
                p={2}
            >
                <Heading
                    fontSize="md"
                    color="blackAlpha.600"
                    fontWeight={'semibold'}
                >
                    Efectivo
                </Heading>
                <Flex flexWrap="wrap" mt={2} gap={1}>
                    <Button
                        onClick={() => {
                            cashHandler(totalPay.toString(), '')
                        }}
                        disabled={disabledButton}
                    >
                        EXACTO
                    </Button>
                    {amounts_cash.map((value, key) => (
                        <Button
                            key={key}
                            disabled={disabledButton}
                            onClick={() => {
                                cashHandler(value.toString(), '')
                            }}
                        >
                            {value}
                        </Button>
                    ))}

                    <ModalKeyboard
                        title={attributes[cashPaymentType.name].title}
                        element={click => (
                            <Button onClick={click} disabled={disabledButton}>
                                OTRO MONTO
                            </Button>
                        )}
                        maxValue={attributes[cashPaymentType.name].maxValue}
                        onlyAmount
                        onDone={attributes[cashPaymentType.name].done}
                        subPaymentTypes={cashPaymentType.sub_payment_types}
                    />
                </Flex>
            </Stack>
        </Box>
    )
}
