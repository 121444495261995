import SubPaymentType from 'models/sub_payment_type'
import { PaymentTypeData } from '../types'

export const getFilterPayments = (
    paymentTypes: PaymentTypeData[]
): (PaymentTypeData & { selectedSubPayment?: SubPaymentType })[] => {
    const filterPaymenTypes = paymentTypes.filter(
        paymentType => paymentType.name !== 'Efectivo'
    )

    const findTransferPayment = paymentTypes.find(
        paymentType => paymentType.name === 'Transferencia'
    )
    const findYapePayment = findTransferPayment?.sub_payment_types?.find(
        sub => sub.name === 'Yape'
    )

    if (findYapePayment) {
        const findCardPayment = paymentTypes.find(
            paymentType => paymentType.name === 'Tarjeta'
        )

        if (!findCardPayment || !findTransferPayment) return []

        const payments = [
            { ...findTransferPayment, selectedSubPayment: findYapePayment },
            findCardPayment,
            findTransferPayment
        ]
        return payments
    } else {
        return [...filterPaymenTypes]
    }
}
