import {
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Image,
    Box
} from '@chakra-ui/react'
import Keys from './Keys'
import GroupRadios from './GroupRadios'
import useAmountField from './useAmountField'
import { DiscountProps } from './types'
import PaymentOptions from './PaymentOptions'
import { paymentLogos } from './PaymentLogos'

export default function ModalKeyboard({
    element,
    maxValue,
    onlyAmount = false,
    onDone,
    title,
    subPaymentTypes,
    ...props
}: DiscountProps) {
    const {
        refInput,
        mode,
        setMode,
        isOpen,
        setOpen,
        submitHandler,
        changeHandler,
        paymentOption,
        setPaymentOption,
        closeModal,
        isUnchecked,
        setUnchecked
    } = useAmountField(maxValue, onDone, onlyAmount, subPaymentTypes, {
        selectedSubPaymentType: props.selectedSubPaymentType,
        defaultValue: props.defaultValue
    })

    return (
        <>
            {element(() => setOpen(true))}
            <Modal isOpen={isOpen} onClose={closeModal} size="lg">
                <ModalOverlay />
                <ModalContent px={{ base: 3, md: 6 }} py={4}>
                    <form onSubmit={submitHandler} noValidate>
                        <ModalBody pb={6}>
                            {props.selectedSubPaymentType ? (
                                <Box
                                    display="flex"
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    h="90px"
                                    w="90px"
                                    minW="90px"
                                    margin={'0 auto'}
                                >
                                    <Image
                                        src={
                                            paymentLogos[
                                                props.selectedSubPaymentType
                                                    .path
                                            ]
                                        }
                                        alt={props.selectedSubPaymentType.name}
                                        maxW="70%"
                                        maxH="70%"
                                        h="auto"
                                        w="auto"
                                    />
                                </Box>
                            ) : (
                                <Text
                                    fontSize="2xl"
                                    fontWeight="bold"
                                    textAlign="center"
                                    mb="3"
                                >
                                    {title}
                                </Text>
                            )}

                            {!props.selectedSubPaymentType ? (
                                <PaymentOptions
                                    type={paymentOption}
                                    onClick={setPaymentOption}
                                    subTypes={subPaymentTypes}
                                    refInput={refInput}
                                    isUnchecked={isUnchecked}
                                    setUnchecked={setUnchecked}
                                />
                            ) : null}

                            <Text textAlign="center">
                                Ingresa la cantidad del monto
                            </Text>
                            <Input
                                w="full"
                                ref={refInput}
                                autoFocus
                                fontSize="24px"
                                fontWeight="bold"
                                h="50px"
                                borderRadius="xl"
                                my="4"
                                onChange={changeHandler}
                            />
                            {!onlyAmount && (
                                <GroupRadios
                                    mode={mode}
                                    refInput={refInput}
                                    onChange={setMode}
                                />
                            )}
                            <Keys
                                refInput={refInput}
                                mode={mode}
                                maxValue={maxValue}
                            />
                        </ModalBody>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
