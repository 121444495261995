import { useToast } from '@chakra-ui/react'
import useStorage from '../../../hooks/useStorage'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import Item from '../../../models/item'
import { formItem, listItem } from '../storage'
import FormItem from './FormItem'
import {
    CHOICE_TYPES,
    getInformationEditModifierGroup,
    getInformationEditProductionItem,
    INVENTORY_TYPES,
    parseModifierGroup,
    parseProductionItem,
    QUANTITY_CHOICE_TYPES,
    SEPARATOR,
    validationModifierGroups
} from './util'

export default function useItem(item?: Item) {
    const { business_variables } = useStorage()
    const isEnabledCommission =
        business_variables.find(bv => bv.code === '015')?.value === '1'

    const { validation, observer, submit } = useSubmit(formItem, {
        path: item ? `/api/items/${item.id}` : '/api/items',
        method: item ? 'put' : 'post',
        done: () => listItem.load()
    })

    const toast = useToast()

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.price.isEmpty('Es requerido asignar un precio')
        v.category_id.isEmpty('Selecciona una categoría')
        if (f?.has_inventory_control && !item?.id) {
            v.minimal_stock.isEmpty('Es requerido asignar el stock')
        }
        if (f.has_commission && isEnabledCommission) {
            v.commission.isEmpty('Es requerido la comisión')
        }
        if (f.inventory_management_type === INVENTORY_TYPES.SIMPLE) {
            if (f.item_production_items.length > 1) {
                v.item_production_items.condition(
                    () => 'Solo debe tener un item en el inventario'
                )
            }
        }
        if (formItem.store()?.modifier_groups.length > 0) {
            const error = validationModifierGroups(
                formItem.store()?.modifier_groups ?? []
            )
            if (Boolean(error)) v.modifier_groups.condition(() => error)
        }
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        price: Number(f.price),
        category_item_id: parseInt(f.category_id),
        image_url: f.image_url || '',
        has_inventory_control: f.has_inventory_control || false,
        ...(f?.has_inventory_control && {
            minimal_stock: Number(f.minimal_stock),
            stock: Number(f.minimal_stock)
        }),
        inventory_management_type: f.inventory_management_type,
        ...(f?.id
            ? {
                  ...getInformationEditProductionItem(
                      f.inventory_management_type ===
                          INVENTORY_TYPES.NO_INVENTORY
                          ? []
                          : f?.item_production_items ?? [],
                      f?.initial_item_production_items ?? []
                  )
              }
            : {
                  item_production_items:
                      f.inventory_management_type ===
                      INVENTORY_TYPES.NO_INVENTORY
                          ? []
                          : f.item_production_items.map(parseProductionItem) ??
                            []
              }),
        features: f.features.map((_f: any) => {
            return {
                ...(_f.item_feature_id && {
                    item_feature_id: _f.item_feature_id
                }),
                id: _f.feature_id,
                quantity: parseInt(_f.quantity)
            }
        }),
        commission: f.commission || 0,
        has_commission: f.has_commission,
        ...(f?.id
            ? {
                  ...getInformationEditModifierGroup(
                      f?.modifier_groups ?? [],
                      f?.initial_modifier_groups ?? []
                  )
              }
            : {
                  modifier_groups:
                      f.modifier_groups.map(parseModifierGroup) ?? []
              })
    }))

    const validationSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev?.preventDefault()
        const inventory_management_type: string =
            formItem.store().inventory_management_type

        if (inventory_management_type !== INVENTORY_TYPES.NO_INVENTORY) {
            const item_production_items: any[] =
                formItem.store().item_production_items ?? []

            if (item_production_items.length > 0) {
                let error = ''

                for (const productionItem of item_production_items) {
                    if (
                        (productionItem.magnitude_id === '' ||
                            productionItem.magnitude_id === undefined) &&
                        Number(productionItem.quantity) <= 0
                    ) {
                        error =
                            error +
                            'Debe seleccionar una unidad y la cantidad debe ser mayor a cero' +
                            SEPARATOR

                        continue
                    }

                    if (
                        productionItem.magnitude_id === '' ||
                        productionItem.magnitude_id === undefined
                    ) {
                        error =
                            error + 'Debe seleccionar una unidad' + SEPARATOR

                        continue
                    }

                    if (Number(productionItem.quantity) <= 0) {
                        error =
                            error +
                            'La cantidad debe ser mayor a cero' +
                            SEPARATOR

                        continue
                    }
                    error = error + SEPARATOR
                }

                const errorSlice = error.slice(0, -1)
                const _errors = errorSlice.split(SEPARATOR)
                const filterErrors = _errors.filter(d => d !== '')

                if (filterErrors.length > 0) {
                    formItem.setHelpers({
                        initial_item_production_items: errorSlice
                    })
                    return
                }
            }
        }

        submit(ev)
    }

    async function openModal() {
        if (item) {
            const { data, error } = await get(`/api/items/${item.id}`)

            if (data) {
                const _item: any = data.result

                const modifier_groups = _item.modifier_group_items?.map(
                    (modifier_group: any) => {
                        return {
                            ...modifier_group,
                            choice_type: modifier_group.is_required
                                ? CHOICE_TYPES.MANDATORY
                                : CHOICE_TYPES.OPTIONAL,
                            quantity_choice_type: !modifier_group.is_required
                                ? ''
                                : modifier_group.has_range
                                ? QUANTITY_CHOICE_TYPES.RANGE
                                : QUANTITY_CHOICE_TYPES.FIXED
                        }
                    }
                )

                const item_production_items = _item.recipe?.recipe_inputs

                formItem.store({
                    id: _item.id.toString() || '',
                    name: _item.name,
                    image_url: _item.image_url,
                    category_id: _item.category_item_id.toString(),
                    price: _item.price.toString(),
                    has_inventory_control: _item.has_inventory_control || false,
                    minimal_stock: _item.minimal_stock?.toString() || '',
                    has_features:
                        (_item.item_features &&
                            _item.item_features?.length !== 0) ||
                        false,
                    features:
                        _item.item_features?.map((item_feature: any) => {
                            return {
                                item_feature_id: item_feature.id,
                                name: item_feature.feature.name,
                                feature_id: item_feature.feature_id,
                                required: item_feature.quantity !== 0,
                                quantity: item_feature.quantity
                            }
                        }) || [],
                    item_production_items: item_production_items ?? [],
                    commission: _item.commission || '',
                    has_commission: !!_item.has_commission,
                    inventory_management_type: _item.inventory_management_type,
                    initial_modifier_groups: modifier_groups,
                    modifier_groups,
                    initial_item_production_items: item_production_items ?? []
                })
                formItem.init(formItem.store)
            }
            if (error) {
                toast({
                    title:
                        error.message ||
                        'Lo sentimos, ocurrió un error al recuperar la información , vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                return
            }
        } else {
            formItem.init()
        }

        modal(FormItem, {
            title: item ? 'Editar producto' : 'Nuevo producto',
            onSubmit: validationSubmit,
            size: '3xl',
            isCentered: false
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    price: number
    minimal_stock: number
    category_item_id: number
    image_url: string
    has_inventory_control: boolean
    features: any[]
    commission: number
    has_commission: boolean
    item_production_items: any[]
}
