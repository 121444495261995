import { Box, Button, Flex, Heading, Image, Select } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { render, useRender } from 'redity'
import CardIcon from '../../../../components/Icon/CardIcon'
import CashIcon from '../../../../components/Icon/CashIcon'
import Transfer from '../../../../components/Icon/Transfer'
import ModalKeyboard from '../../../../components/ModalKeyboard'
import { Keys } from '../../constants'
import usePaymentMethods from '../../hooks/usePaymentMethods'
import useSelectedItems from '../../hooks/useSelectedItems'
import { storagePage } from '../../storage'
import CreditPayments from './CreditPayments'
import TablePayments from './TablePayments'
import useStorage from '../../../../hooks/useStorage'
import CashPayments from './CashPayments'
import { getFilterPayments } from '../../utilities/utils'
import { paymentLogos } from 'components/ModalKeyboard/PaymentLogos'

export default function PaymentMethods() {
    useRender(Keys.PAYMENTS_METHODS)
    const { cashHandler, cardHandler, transferHandler, disabledButton } =
        usePaymentMethods()

    const [paymentCondition, setPaymentCondition] = useState('1')
    const { paymentTypes, type } = storagePage

    const { business_variables } = useStorage()

    const enable_credit_sales = business_variables.find(
        v => v.code === '021'
    )?.value

    function maxAmount() {
        const { total } = useSelectedItems()
        const cash = storagePage.cash.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const card = storagePage.card.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const transfer = storagePage.transfer.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const accumulatedPayment = cash + card + transfer
        const lack = total - accumulatedPayment
        if (lack < 0) return 0
        else return Number(lack.toFixed(2))
    }

    const paymentIcons: any = {
        CashIcon: <CashIcon fill="gray" />,
        CardIcon: <CardIcon fill="gray" />,
        Transfer: <Transfer fill="gray" />
    }

    const attributes: any = {
        Efectivo: {
            title: 'Monto en efectivo',
            maxValue: Infinity,
            done: cashHandler
        },
        Tarjeta: {
            title: 'Monto por tarjeta',
            maxValue: maxAmount(),
            done: cardHandler
        },
        Transferencia: {
            title: 'Monto por transferencia',
            maxValue: maxAmount(),
            done: transferHandler
        }
    }

    useEffect(() => {
        setPaymentCondition('1')
    }, [type == 'ticket'])

    function changeConditionHandler(ev: ChangeEvent<HTMLSelectElement>) {
        setPaymentCondition(ev.target.value)

        if (ev.target.value == '1') {
            storagePage.creditFees = []
            storagePage.condition = 'cash'
        }
        if (ev.target.value == '2') {
            storagePage.cash = []
            storagePage.card = []
            storagePage.transfer = []
            storagePage.condition = 'credit'
        }
        render(Keys.TABLE_CREDITS)
        render(Keys.TABLE_PAYMENTS)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
    }

    const totalPay = maxAmount()

    const cashPaymentType = paymentTypes.find(
        paymentType => paymentType.name === 'Efectivo'
    )

    const filterPaymentTypes = getFilterPayments(paymentTypes)

    return (
        <>
            <Box
                mt={{
                    xl: 4,
                    md: 4,
                    base: 3
                }}
            >
                <Flex
                    gridGap={6}
                    flexDirection={{
                        base: 'column',
                        lg: 'row'
                    }}
                >
                    {type != 'ticket' && (
                        <Box
                            minW="120px"
                            hidden={
                                enable_credit_sales === undefined ||
                                enable_credit_sales === '0'
                                    ? true
                                    : false
                            }
                        >
                            <Heading fontSize="md" mb={4}>
                                Cond. de Pago
                            </Heading>
                            <Select
                                size="sm"
                                onChange={changeConditionHandler}
                                disabled={type == 'free' ? true : false}
                                value={paymentCondition}
                            >
                                <option value={'1'}>Contado</option>
                                <option value={'2'}>Crédito</option>
                            </Select>
                        </Box>
                    )}

                    {paymentCondition == '1' && (
                        <Box>
                            <Heading fontSize="md" mb={4}>
                                Métodos de Pago
                            </Heading>
                            {cashPaymentType && (
                                <CashPayments
                                    totalPay={totalPay}
                                    disabledButton={disabledButton}
                                    attributes={attributes}
                                    cashPaymentType={cashPaymentType}
                                />
                            )}

                            <Flex gridGap={{ base: 2, lg: 3 }} wrap="wrap">
                                {filterPaymentTypes.map((paymentType, i) => (
                                    <ModalKeyboard
                                        key={i}
                                        title={
                                            attributes[paymentType.name].title
                                        }
                                        defaultValue={totalPay}
                                        element={click => (
                                            <>
                                                {paymentType.selectedSubPayment ? (
                                                    <Box
                                                        border="1px solid"
                                                        borderColor={
                                                            'blackAlpha.600'
                                                        }
                                                        borderRadius={'md'}
                                                        p={1}
                                                        onClick={() => {
                                                            if (disabledButton)
                                                                return
                                                            click()
                                                        }}
                                                    >
                                                        <Image
                                                            h="40px"
                                                            minW="80px"
                                                            cursor={'pointer'}
                                                            src={
                                                                paymentLogos[
                                                                    paymentType
                                                                        .selectedSubPayment
                                                                        .path
                                                                ]
                                                            }
                                                            alt={
                                                                paymentType
                                                                    .selectedSubPayment
                                                                    .name
                                                            }
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Button
                                                        colorScheme="blackAlpha"
                                                        variant="outline"
                                                        leftIcon={
                                                            paymentIcons[
                                                                paymentType
                                                                    .class_name
                                                            ]
                                                        }
                                                        size="sm"
                                                        onClick={click}
                                                        disabled={
                                                            disabledButton
                                                        }
                                                        fontSize={{
                                                            base: 'sm',
                                                            lg: 'md'
                                                        }}
                                                    >
                                                        {paymentType.name}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        maxValue={
                                            attributes[paymentType.name]
                                                .maxValue
                                        }
                                        onlyAmount
                                        onDone={
                                            attributes[paymentType.name].done
                                        }
                                        subPaymentTypes={
                                            paymentType.sub_payment_types
                                        }
                                        selectedSubPaymentType={
                                            paymentType?.selectedSubPayment
                                        }
                                    />
                                ))}
                            </Flex>
                        </Box>
                    )}
                </Flex>
                {paymentCondition == '1' && <TablePayments />}
                {paymentCondition == '2' && <CreditPayments />}
            </Box>
        </>
    )
}
