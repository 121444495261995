import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { FindAllDeliveries } from '../../../Deliveries/types'
import { DeliveryStatus } from 'types/deliveryMode'
import { Icon, useDisclosure } from '@chakra-ui/react'
import Pencil from 'components/Icon/Pencil'
import UpdateMotorizedModal from './UpdateMotorizedModal'

interface Props {
    delivery: FindAllDeliveries[number]
    onLoad: () => void
}

const Motorized = React.memo(({ delivery, onLoad }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const showEdit = [
        DeliveryStatus.ON_THE_WAY,
        DeliveryStatus.DELIVERED
    ].includes(delivery.status)

    return (
        <>
            <Box display={'flex'} gap={2} alignItems={'center'}>
                <Text fontSize="sm">
                    {delivery.profile
                        ? `${delivery.profile.firstname} ${delivery.profile.lastname}`
                        : '-'}
                </Text>
                {showEdit && (
                    <>
                        <Icon
                            fill="blue.400"
                            as={Pencil}
                            cursor="pointer"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                onOpen()
                            }}
                        />
                        <UpdateMotorizedModal
                            isOpen={isOpen}
                            handleClose={onClose}
                            delivery={delivery}
                            onLoad={onLoad}
                        />
                    </>
                )}
            </Box>
        </>
    )
})

Motorized.displayName = 'Motorized'

export default Motorized
