import {
    Box,
    Divider,
    Flex,
    Grid,
    Heading,
    ModalCloseButton,
    Text
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import TypeIdentificationDocument from '../../../../utilities/type_identification_document'
import { OrderPaymentForView } from '../../types'
import rearrangeItems from '../../utilities/rearrangeItems'
import { OrderItem, ResultItem } from './Components'

export default function Content({
    orderPayment
}: {
    orderPayment: OrderPaymentForView
}) {
    const name =
        orderPayment.client.type_identification_document_id === 2
            ? orderPayment.client.business_name
            : `${orderPayment.client.firstname} ${orderPayment.client.lastname}`

    const orderItems: any = []
    orderPayment.account_order.order_payment_items.map(order_paymet_item =>
        orderItems.push({
            ...order_paymet_item.order_item,
            quantity: order_paymet_item.quantity
        })
    )
    const categoryItems = rearrangeItems(orderItems)

    const saleChannel = orderPayment.order.table
        ? 'Mesa ' + orderPayment.order.table.name
        : orderPayment.type_consumption.name

    return (
        <>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                Venta
            </Text>
            <ModalCloseButton mt={1} />

            <Text textAlign="center">
                ({dayjs(orderPayment.created_at).format('DD/MM/YYYY hh:mm A')})
            </Text>
            <Box bg="base-3" py="4" px="6" mt={3} borderRadius="xl">
                <Heading textAlign="center" fontSize="md" mb={2}>
                    PEDIDO
                </Heading>
                {categoryItems.map(categoryItem => (
                    <Fragment key={categoryItem.id}>
                        <Text fontWeight="bold" fontSize="sm">
                            {categoryItem.name}
                        </Text>
                        {categoryItem.items.map(item => (
                            <OrderItem
                                key={item.id}
                                quantity={item.quantity}
                                cost={item.price}
                            >
                                {item.name}
                            </OrderItem>
                        ))}
                    </Fragment>
                ))}
                <Divider borderColor="gray" mb={2} mt={4} />
                <ResultItem
                    title="SUBTOTAL"
                    value={orderPayment.total_original}
                />
                <ResultItem title="DESCUENTO" value={orderPayment.discount} />
                <Divider borderColor="gray" my={2} />
                <ResultItem title="TOTAL" value={orderPayment.total_final} />
                <ResultItem
                    title="CLIENTE PAGA"
                    value={orderPayment.accumulated_payment}
                />
                <ResultItem title="VUELTO" value={orderPayment.cashback} />
            </Box>
            <Box
                borderTop="2px solid"
                borderRight="2px solid"
                borderLeft="2px solid"
                borderColor="gray-2"
                mt={4}
            >
                <Box
                    borderBottom="2px solid"
                    borderColor="gray-2"
                    px="4"
                    py="2"
                >
                    {orderPayment.sunat_voucher && (
                        <Text as="b">
                            {orderPayment.sunat_voucher.type_voucher_id === 1
                                ? 'Boleta'
                                : 'Factura'}
                        </Text>
                    )}
                    {orderPayment.sunat_voucher && (
                        <Text>{orderPayment.sunat_voucher.code}</Text>
                    )}
                    <Text>
                        Fecha de emisión:{' '}
                        {orderPayment.proof_payment == 4
                            ? dayjs(orderPayment.created_at).format(
                                  'DD/MM/YYYY'
                              )
                            : dayjs(
                                  orderPayment.sunat_voucher?.issue_date
                              ).format('DD/MM/YYYY')}
                    </Text>
                </Box>
                <Box
                    borderBottom="2px solid"
                    borderColor="gray-2"
                    px="4"
                    py="2"
                >
                    <Text as="b">{name}</Text>
                    <Text>
                        {
                            TypeIdentificationDocument[
                                orderPayment.client
                                    .type_identification_document_id
                            ]
                        }
                        : {orderPayment.client.number}
                    </Text>
                    <Text>Dirección: {orderPayment.client.address || '-'}</Text>
                </Box>
                <Flex>
                    <Box
                        px="4"
                        py="2"
                        borderBottom="2px solid"
                        borderColor="gray-2"
                        w="50%"
                    >
                        <Text as="b">Canal de venta</Text>
                        <Text>{saleChannel}</Text>
                    </Box>
                    <Box
                        px="4"
                        py="2"
                        borderBottom="2px solid"
                        borderLeft="2px solid"
                        borderColor="gray-2"
                        w="50%"
                    >
                        <Text as="b">Cond. de pago</Text>
                        <Text>{orderPayment.status}</Text>
                    </Box>
                </Flex>
                {orderPayment.status == 'CRÉDITO' && (
                    <Box
                        px="4"
                        py="2"
                        borderBottom="2px solid"
                        borderColor="gray-2"
                    >
                        <Grid templateColumns="repeat(3,1fr)">
                            <Text as="b">Cuota</Text>
                            <Text as="b">Fecha</Text>
                            <Text as="b" align="center">
                                Monto
                            </Text>
                        </Grid>
                        {orderPayment.outstanding_quotas.map((quota, i) => (
                            <Grid templateColumns="repeat(3,1fr)" key={i}>
                                <Text>Cuota #{i + 1}</Text>
                                <Text>{quota.due_date}</Text>
                                <Text align="right">
                                    S/ {quota.amount.toFixed(2)}
                                </Text>
                            </Grid>
                        ))}
                    </Box>
                )}
                <Box
                    px="4"
                    py="2"
                    borderBottom="2px solid"
                    borderColor="gray-2"
                >
                    <Grid templateColumns="repeat(2,1fr)">
                        <Text as="b">Método de pago</Text>
                        <Text as="b" align="right">
                            Monto
                        </Text>
                    </Grid>
                    {orderPayment.order_payment_details.map(
                        (orderPaymentDetail, index) => (
                            <Grid key={index} templateColumns="repeat(2,1fr)">
                                <Text>
                                    {orderPaymentDetail.sub_payment_type
                                        ? orderPaymentDetail.type_payment
                                              .sub_payment_type.name
                                        : orderPaymentDetail.type_payment.name}
                                </Text>
                                <Text align="right">
                                    S/ {orderPaymentDetail.amount.toFixed(2)}
                                </Text>
                            </Grid>
                        )
                    )}
                </Box>
            </Box>
        </>
    )
}
