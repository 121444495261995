import { useEffect, useState } from 'react'
import CashboxUser from '../../../models/cashbox_user'
import { get } from '../../../lib/http'
import useSession from '../../../hooks/useSession'

export default function useOpenCashbox() {
    const [isOpen, setIsOpen] = useState(false)
    const { isLogged } = useSession()

    useEffect(() => {
        if (!isLogged) {
            setIsOpen(true)
            return
        }
        get<CashboxUser | null>('/api/cashbox_users/cash_box_user').then(
            result => {
                if (result.data && result.data.result) {
                    setIsOpen(true)
                }
            }
        )
    }, [])

    return {
        status,
        isOpen
    }
}
