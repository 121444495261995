import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ProductGroup } from '../../../context/DeliveryContext/types'
import useDelivery from '../../../hooks/useDelivery'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'
import {
    getModifierGroups,
    getTotalPrice
} from 'components/ModalModifiersItem/utils'
import itemHash from 'utilities/item-hash'

export default function useInitial() {
    const { delivery_id } = useParams<{ delivery_id: string }>()
    const {
        delivery,
        setProducts,
        isLoading,
        orderEdition,
        isOrderEdition,
        setInitialProducts
    } = useDelivery(Number(delivery_id || '0'))
    const set = useDeliveryForm(state => state.set)
    const clearHelper = useDeliveryHelper(state => state.clear)

    useEffect(() => {
        if (!delivery) return
        if (isOrderEdition) {
            orderEdition(false)
            return
        }

        //Se verifica la dirección, por si es que edito después que ya se habia registrado el delivery
        let addressDelivery: string[] = []
        const findAddress = delivery.client.addresses.find(
            address => address[0] === delivery.address
        )
        if (findAddress) {
            const [address, reference] = findAddress
            addressDelivery = [address, reference ?? '']
        }

        set({
            phone: delivery.phone_number,
            address: addressDelivery,
            deliveryMode: delivery.programmed ? '1' : '0',
            paymentMethod: delivery.payment_method.toString(),
            priceDelivery: delivery.delivery_price.toString(),
            date: dayjs(delivery.delivery_date).format('YYYY-MM-DD'),
            hour: dayjs(delivery.delivery_date).format('HH:mm'),
            cashClient: delivery.cash_client.toString(),
            observation: delivery.observation || ''
        })

        const productsGroup: ProductGroup[] = []

        delivery.order.order_items.forEach(orderItem => {
            const productGroup = productsGroup.find(
                prdG =>
                    prdG.category_item_id === orderItem.item.category_item_id
            )
            const modifierGroups = getModifierGroups(
                orderItem.modifier_orders ?? []
            )
            const total_price =
                getTotalPrice(orderItem.item.price ?? 0, modifierGroups) ?? 0

            if (productGroup) {
                productGroup.items.push({
                    hash: itemHash({
                        itemId: orderItem.item_id,
                        orderItemId: orderItem.id
                    }),
                    orderItemId: orderItem.id,
                    itemId: orderItem.item_id,
                    name: orderItem.item.name,
                    quantity: orderItem.quantity,
                    price: orderItem.item.price,
                    description: '',
                    feature_settings: [],
                    modifier_groups: modifierGroups,
                    isForEdition: true,
                    total_price
                })
                return
            }

            productsGroup.push({
                category_item_id: orderItem.item.category_item_id,
                category_item_name: orderItem.item.category_item.name,
                items: [
                    {
                        hash: itemHash({
                            itemId: orderItem.item_id,
                            orderItemId: orderItem.id
                        }),
                        orderItemId: orderItem.id,
                        itemId: orderItem.item_id,
                        name: orderItem.item.name,
                        quantity: orderItem.quantity,
                        price: orderItem.item.price,
                        description: '',
                        feature_settings: [],
                        modifier_groups: modifierGroups,
                        isForEdition: true,
                        total_price
                    }
                ]
            })
        })

        setProducts(productsGroup)
        setInitialProducts(productsGroup)

        return clearHelper
    }, [delivery?.id])

    return {
        isLoading
    }
}
