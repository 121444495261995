import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import CommandCard from './CommandCard'
import { CommandHistoryData, ModalCommandHistoryProps } from './types'
import { useGet } from 'hooks/useHttp'

export default function ModalCommandHistory({
    isOpen,
    onClose,
    orderId,
    delivery
}: ModalCommandHistoryProps) {
    const defaultData = {
        commands: []
    }

    const { data } = useGet<CommandHistoryData>(
        `/api/commands/order/${orderId}`,
        defaultData
    )

    const commandHistory = data.result

    //Ordenando comandas desde la más reciente
    const commands = commandHistory.commands.sort((a, b) => b.id - a.id)

    const clientDelivery = delivery?.client

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="xl" fontWeight="bold">
                    Historial de comandas
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Flex
                        justifyContent="space-between"
                        mb="4"
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Text as={'b'}>
                            Pedido N°{' '}
                            {commandHistory.order?.id
                                .toString()
                                .padStart(5, '0')}
                        </Text>
                        <Flex
                            justifyContent="space-between"
                            gridGap={{ md: 20 }}
                        >
                            {clientDelivery ? (
                                <Text>
                                    Cliente:{' '}
                                    <Text color="dark-2" as={'b'}>
                                        {clientDelivery.firstname}{' '}
                                        {clientDelivery.lastname}
                                    </Text>
                                </Text>
                            ) : (
                                <>
                                    {commandHistory.order?.table && (
                                        <Text>
                                            Mesa:{' '}
                                            <Text color="dark-2" as={'b'}>
                                                {
                                                    commandHistory.order?.table
                                                        .name
                                                }
                                            </Text>
                                        </Text>
                                    )}

                                    <Text>
                                        Mozo:{' '}
                                        <Text color="dark-2" as={'b'}>
                                            {
                                                commandHistory.order?.user
                                                    .firstname
                                            }{' '}
                                            {
                                                commandHistory.order?.user
                                                    .lastname
                                            }
                                        </Text>
                                    </Text>
                                </>
                            )}
                        </Flex>
                    </Flex>
                    <Flex direction="column" gridGap={4}>
                        {commands.map((command, i) => (
                            <CommandCard
                                key={i}
                                command={command}
                                deliveryId={delivery?.id}
                            />
                        ))}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
