import { ChangeEvent, createRef, FormEvent, useEffect, useState } from 'react'
import SubPaymentType from '../../models/sub_payment_type'
import onlyNumber from '../../utilities/onlyNumber'

export default function useAmountField(
    maxValue: number,
    onDone: (value: string, mode: string, name?: string) => void,
    onlyAmount: boolean,
    subPaymentTypes?: SubPaymentType[],
    _data?: {
        selectedSubPaymentType?: SubPaymentType
        defaultValue?: number
    }
) {
    const refInput = createRef<HTMLInputElement>()
    const [mode, setMode] = useState('amount')
    const [isOpen, setOpen] = useState(false)
    const [paymentOption, setPaymentOption] = useState('')
    const [isUnchecked, setUnchecked] = useState(false)
    const [selectedSubPaymentType, setSelectedSubPaymentType] =
        useState<SubPaymentType | null>(null)

    useEffect(() => {
        if (!isOpen) return

        const intervalSetDefaultValue = setInterval(() => {
            if (refInput.current) {
                const elem = refInput.current as HTMLInputElement
                if (_data?.defaultValue) {
                    elem.value = _data.defaultValue.toString()
                }

                const selectedSubPaymentTypeId =
                    _data?.selectedSubPaymentType?.id

                if (
                    selectedSubPaymentTypeId &&
                    subPaymentTypes &&
                    subPaymentTypes?.length > 0
                ) {
                    const findSubPaymentType = subPaymentTypes?.find(
                        data => data.id === selectedSubPaymentTypeId
                    )
                    if (findSubPaymentType) {
                        setPaymentOption(selectedSubPaymentTypeId.toString())
                        // setSelectedSubPaymentType(findSubPaymentType)
                    }
                }

                clearInterval(intervalSetDefaultValue)
            }
        }, 10)

        return () => clearInterval(intervalSetDefaultValue)
    }, [isOpen])

    const subPaymentName = subPaymentTypes?.find(
        subPaymentType => subPaymentType.id == Number(paymentOption)
    )?.name

    function changeAmountHandler(ev: ChangeEvent<HTMLInputElement>) {
        const v = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max: 10000000,
            negative: false
        })
        const value = Number(v)
        const elem = refInput.current as HTMLInputElement
        if (value > maxValue) {
            elem.value = maxValue.toString()
        } else {
            elem.value = v
        }
    }

    function changePercentageHandler(ev: ChangeEvent<HTMLInputElement>) {
        const v = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max: 10000000,
            negative: false
        })
        const value = Number(v)
        const elem = refInput.current as HTMLInputElement
        if (value >= 99.99) {
            elem.value = '99.99'
        } else {
            elem.value = v
        }
    }

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const elem = refInput.current as HTMLInputElement
        if (
            subPaymentTypes &&
            subPaymentTypes.length > 0 &&
            paymentOption === ''
        ) {
            setUnchecked(true)
            return
        }
        if (elem.value === '') return
        setOpen(false)
        setPaymentOption('')
        if (onlyAmount) {
            onDone(elem.value, paymentOption, subPaymentName)
        } else {
            onDone(elem.value, mode)
        }
    }

    function closeModal() {
        setOpen(false)
        setPaymentOption('')
        setUnchecked(false)
        setSelectedSubPaymentType(null)
    }

    return {
        changeHandler:
            mode === 'amount' ? changeAmountHandler : changePercentageHandler,
        mode,
        setMode,
        refInput,
        isOpen,
        setOpen,
        submitHandler,
        paymentOption,
        setPaymentOption,
        closeModal,
        isUnchecked,
        setUnchecked,
        selectedSubPaymentType
    }
}
