import Client from '../../../models/client'
import { SelectedItem } from '../../../types/runway_state'
import { DataFeatureSetting } from '../../QuickSale/utilities/getItemsForPost'
import { storagePage } from '../storage'
import { BodyRegister, BodyRegisterItem, BodyRegisterPayment } from '../types'
import { DateWithTimeCurrent } from '../../../utilities/date_tools'

export default function getBodyPayment(
    subtotal: number,
    total: number,
    selectedItems: SelectedItem[],
    userId: number | null,
    isToClose: boolean
): BodyRegister {
    const items = selectedItems.map(item => item.items)
    const nextItems: Array<BodyRegisterItem> = []

    for (const item of items.flat()) {
        const dataItem: BodyRegisterItem = {
            id: item.id,
            quantity: item.quantity,
            aditional_information: item.description,
            price: item.price,
            user_price_edition: userId
        }

        if (item.feature_settings.length > 0) {
            //const nextItem = nextItems.find(_item => _item.id === item.id)

            const feature_setting_sub_features: DataFeatureSetting['feature_setting_sub_features'] =
                item.feature_settings
                    .map(fs =>
                        fs.feature_setting_sub_features.map(fssf => ({
                            sub_feature_id: fssf.sub_feature_id,
                            quantity: fssf.quantity,
                            price_feature: fssf.price,
                            aditional_information: fssf.aditional_information
                        }))
                    )
                    .flat()
            /*
            if (nextItem) {
                nextItem.quantity = nextItem.quantity + item.quantity
                const featureSettings =
                    nextItem.feature_settings as DataFeatureSetting[]

                featureSettings.push({
                    quantity: item.quantity,
                    feature_setting_sub_features
                })
            } else {
                nextItems.push({
                    ...dataItem,
                    quantity: item.quantity,
                    feature_settings: [
                        {
                            quantity: item.quantity,
                            feature_setting_sub_features
                        }
                    ]
                })
            }
            */

            nextItems.push({
                ...dataItem,
                quantity: item.quantity,
                feature_settings: [
                    {
                        quantity: item.quantity,
                        feature_setting_sub_features
                    }
                ]
            })
        } else if (item.modifier_groups?.length > 0) {
            const modifiers = item.modifier_groups
                .map(fs =>
                    fs.modifier_group_modifiers.map(fssf => ({
                        modifier_id: fssf.modifier_id,
                        quantity: fssf.quantity,
                        price: fssf.price
                    }))
                )
                .flat()
            nextItems.push({
                ...dataItem,
                quantity: item.quantity,
                modifiers: [...modifiers]
            })
        } else {
            nextItems.push(dataItem)
        }
    }

    let cash = 0
    for (let i = 0; i < storagePage.cash.length; i++) {
        cash += storagePage.cash[i].amount
    }
    let card = 0
    for (let i = 0; i < storagePage.card.length; i++) {
        card += storagePage.card[i].amount
    }
    let transfer = 0
    for (let i = 0; i < storagePage.transfer.length; i++) {
        transfer += storagePage.transfer[i].amount
    }

    const payments: BodyRegisterPayment[] = []
    if (storagePage.cash.length > 0) {
        payments.push({
            type_payment_id: 1,
            amounts: storagePage.cash
        })
    }

    if (storagePage.card.length > 0) {
        payments.push({
            type_payment_id: 2,
            amounts: storagePage.card
        })
    }

    if (storagePage.transfer.length > 0) {
        payments.push({
            type_payment_id: 3,
            amounts: storagePage.transfer
        })
    }

    const client = storagePage.clientSelected as Client

    const TypeConsumption = {
        eat: 1, // para comer
        carry_out: 2, // para llevar
        delivery: 3, // delivery
        pick_up: 4 // recojo
    }

    const ProffPayment = {
        ticket: 1, // boleta
        invoice: 2, // factura
        free: 3 // cortesia
    }

    const TypeTax = {
        levied: 1, // gravado
        exempt: 2 // exonerado
    }

    const accumulatedPayment = cash + card + transfer

    const turned = accumulatedPayment - total
    //const payment_quotas = storagePage.creditFees
    // formatear fecha de quotas de vencimiento
    const creditFees: any[] = []
    storagePage.creditFees.map(quota =>
        creditFees.push({
            due_date: DateWithTimeCurrent(quota.due_date),
            amount: quota.amount
        })
    )

    return {
        client_id: client.id,
        type_consumption_id: TypeConsumption[storagePage.to],
        discount:
            storagePage.typeDiscount === 'amount' ? storagePage.discount : 0,
        percentage_discount:
            storagePage.typeDiscount === 'percentage'
                ? storagePage.discount
                : 0,
        cashback: turned < 0 ? 0 : Number(turned.toFixed(2)),
        proof_payment:
            storagePage.type === null || storagePage.type === 'free'
                ? null
                : ProffPayment[storagePage.type],
        accumulated_payment: accumulatedPayment,
        total_original: subtotal,
        total_final: total,
        alias: storagePage.alias,
        items: nextItems.flat(),
        free: storagePage.type ? ProffPayment[storagePage.type] === 3 : false,
        ...((storagePage.type == 'ticket' || storagePage.type == 'invoice') && {
            type_tax: TypeTax[storagePage.typeTax]
        }),
        ...(storagePage.condition == 'cash'
            ? { payments: payments }
            : { payment_quotas: creditFees }),
        reason: storagePage.reason,
        deliveryId: storagePage.deliveryId,
        close: isToClose
    }
}
